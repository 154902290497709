import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Modal,
  Card,
  CircularProgress,
  Grid,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BulletIcon from "@mui/icons-material/Circle";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectEmail, selectSupplierId } from "../../selectors/login.selector";
import { checkInternalEmail } from "../../utils/lib";
import { getListOfAllAudits } from "../../reducers/audit.reducer";
import AuditDocumentDrop from "../AuditDetails/auditDocumentDrop.component";
const CapApproved = ({
  immediateActions,
  longTermActions,
  status,
  rootCauseAnalysis,
  responsiblePerson,
  targetDate,
  rootCause,
}) => {
  // State to manage uploaded files
  // immediateActions = [
  //   "Schedule and conduct an evacuation drill within the next week, involving all employees and shifts.",
  // ];
  const dispatch = useDispatch();
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);

  const supplierId = params.supplierId;
  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setUploading(true);
    setTimeout(() => {
      refreshAuditList();
      setUploading(false);
    }, 5000); // Wait for 5 seconds before refreshing the list
  };
  const refreshAuditList = () => {
    dispatch(
      getListOfAllAudits({
        limit: 10,
        offset: 0,
        supplierId: supplierId,
      })
    );
  };
  // longTermActions = [
  //   "Assign a senior safety manager to oversee emergency preparedness",
  //   "Create a yearly calendar for evacuation drills, with at least two per year",
  //   "Develop a checklist for planning and executing drills",
  //   "After each drill, gather feedback and make improvements",
  //   "Keep detailed records of all drills, including participation and Findings found.",
  // ];
  const [uploadedFile, setUploadedFile] = useState(null);

  // Handler to simulate file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
    }
  };

  return (
    <Box sx={{ mt: 5, mb: 3 }}>
      {/* Title */}
      <Typography
        sx={{
          mb: 2,
          fontWeight: "500",
          fontSize: "30px",
          color: "#475467",
          textDecorationLine: "underline",
        }}
      >
        Approved Site Input
      </Typography>

      <Divider sx={{ mb: 3 }} />

      <Grid container sx={{ mb: 3 }}>
        <Grid item xs={4}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause
          </Typography>
          <List dense sx={{ paddingLeft: 0, ml: 0 }}>
            {[rootCause].map((action, index) => (
              <ListItem key={index}>
                {/* <ListItemIcon
              sx={{  paddingLeft: 0, marginLeft: 0 }}
            >
              <BulletIcon sx={{ color: "black", fontSize: "10px" }} />
            </ListItemIcon> */}
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={8}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
            }}
          >
            Root Cause Analysis
          </Typography>
          <List dense sx={{ paddingLeft: 0, ml: 0 }}>
            {[rootCauseAnalysis].map((action, index) => (
              <ListItem key={index}>
                {/* <ListItemIcon
              sx={{  paddingLeft: 0, marginLeft: 0 }}
            >
              <BulletIcon sx={{ color: "black", fontSize: "10px" }} />
            </ListItemIcon> */}
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
      {/* Immediate Corrective Action */}
      <Typography
        sx={{ mb: 1, fontWeight: "700", fontSize: "16px", color: "#475467" }}
      >
        Immediate Corrective Action
      </Typography>
      <List dense sx={{ paddingLeft: 0, ml: 0 }}>
        {[immediateActions].map((action, index) => (
          <ListItem key={index}>
            {/* <ListItemIcon
              sx={{  paddingLeft: 0, marginLeft: 0 }}
            >
              <BulletIcon sx={{ color: "black", fontSize: "10px" }} />
            </ListItemIcon> */}
            <ListItemText primary={action} />
          </ListItem>
        ))}
      </List>

      {/* Long Term Preventative Action */}
      <Typography
        sx={{
          mb: 1,
          fontWeight: "700",
          fontSize: "16px",
          color: "#475467",
          mt: 3,
        }}
      >
        Long Term Preventative Action
      </Typography>
      <List dense>
        {[longTermActions].map((action, index) => (
          <ListItem key={index}>
            <ListItemText primary={action} />
          </ListItem>
        ))}
      </List>
      <Grid
        container
        sx={{
          backgroundColor: "#F9FAFB",
          borderRadius: 2,
          px: 2,
        }}
      >
        <Grid item xs={6}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Responsible Person
          </Typography>
          <List dense>
            {[responsiblePerson].map((action, index) => (
              <ListItem key={index}>
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              mb: 1,
              fontWeight: "700",
              fontSize: "16px",
              color: "#475467",
              mt: 3,
            }}
          >
            Target Date
          </Typography>
          <List dense>
            {[targetDate].map((action, index) => (
              <ListItem key={index}>
                <ListItemText primary={action} />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CapApproved;
