import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPStatus,
  selectCurrentStepInCapManagement,
  selectFirstAuditIssueId,
} from "../../selectors/audit.selector";
import { setSelectedAuditIssueId } from "../../reducers/audit.reducer";
import AuditCapSubProgressStepper from "./auditCapSubProgressStepper.component";

const AuditCapProgressStepper = ({ capDetails = false, auditData }) => {
  const dispatch = useDispatch();
  const firstAuditIssueId = useSelector(selectFirstAuditIssueId);
  const auditCAPStatus = useSelector(selectAuditCAPStatus);
  const handleClick = () => {
    dispatch(setSelectedAuditIssueId(firstAuditIssueId));
  };
  const selectedCurrentStepInCapManagement = useSelector(
    selectCurrentStepInCapManagement
  );
  const steps = [
    { label: "Demo Audit Review" },
    { label: "Supplier CAP Input" },
    { label: "Demo CAP Review" },
    { label: "Supplier Additional Information Needed" },
    { label: "Demo CAP Closure Review" },
  ];
  const mappingOfCAPStatusToStep = {
    OPEN: 0,
    IN_PROGRESS: 1,
    BRAND_REVIEWING_PLAN: 2,
    BRAND_APPROVED_PLAN: 3,
    SITE_ADDING_PROOF: 4,
    BRAND_APPROVING_PROOF: 4,
    BRAND_APPROVED_PROOF: 5,
    CLOSED: 5,
  };
  const currentStep = mappingOfCAPStatusToStep[auditCAPStatus];
  console.log("currentStep", currentStep);
  const getStepIcon = (index) => {
    if (index < mappingOfCAPStatusToStep[auditCAPStatus]) {
      return <CheckCircleIcon color="success" />;
    } else if (index === mappingOfCAPStatusToStep[auditCAPStatus]) {
      return (
        <Box position="relative" display="inline-flex">
          <CircleIcon color="primary" />
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            top={0}
            left={0}
            bottom={0}
            right={0}
          >
            <Typography
              variant="caption"
              component="div"
              color="white"
              sx={{ zIndex: 100 }}
            >
              {index + 1}
            </Typography>
          </Box>
        </Box>
      );
    } else {
      return (
        <Box position="relative" display="inline-flex">
          <CircleIcon sx={{ color: "#BABABA" }} />
          <Box
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            top={0}
            left={0}
            bottom={0}
            right={0}
          >
            <Typography
              variant="caption"
              component="div"
              color="white"
              sx={{ zIndex: 100 }}
            >
              {index + 1}
            </Typography>
          </Box>
        </Box>
      );
    }
  };

  return (
    auditCAPStatus !== "OPEN" &&
    (!capDetails ? (
      <Box borderRadius={2} p={3} sx={{ backgroundColor: "#F8F8F8", m: 4 }}>
        <Grid container spacing={2} alignItems="center">
          {/* Header for CAP Management Progress */}
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {auditCAPStatus === "CLOSED" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#4CAF50",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "700", fontSize: "28px", mb: 2 }}>
                  CAP Management Completed
                </Typography>
              </Box>
            ) : (
              <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                CAP Management Progress
              </Typography>
            )}
          </Grid>
          {/* Stepper Progress */}
          <Grid item xs={12} sx={{ p: 0, m: 0 }}>
            <Stepper alternativeLabel sx={{ p: 0, m: 0 }}>
              {steps.map((step, index) => (
                <Step
                  key={index}
                  completed={index < mappingOfCAPStatusToStep[auditCAPStatus]}
                  sx={{ p: 0, m: 0 }}
                >
                  <StepLabel
                    sx={{ m: 0, p: 0 }}
                    StepIconComponent={() => getStepIcon(index)}
                  >
                    {step.label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {/* Current step details */}

          <Grid item xs={12}>
            {currentStep > 0 && currentStep < 5 && (
              <AuditCapSubProgressStepper
                steps={steps}
                currentStep={currentStep}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    ) : (
      <Grid container sx={{ my: 1 }}>
        <Grid item xs={12} sx={{ p: 0, m: 0 }}>
          <Stepper alternativeLabel sx={{ p: 0, m: 0 }}>
            {steps.map((step, index) => (
              <Step
                key={index}
                completed={index < mappingOfCAPStatusToStep[auditCAPStatus]}
                sx={{ p: 0, m: 0 }}
              >
                <StepLabel
                  sx={{ m: 0, p: 0 }}
                  StepIconComponent={() => getStepIcon(index)}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
    ))
  );
};

export default AuditCapProgressStepper;
