import React from "react";
import {
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  Link,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditCAPUploadedProof,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import { updateIssueData } from "../../reducers/audit.reducer";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const AuditCapDetailsStatusUploadedProof = ({ data }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const uploadedProof = useSelector(selectAuditCAPUploadedProof);
  const selectedIssueId = useSelector(selectSelectedAuditIssueId);

  const handleApprove = (issueId) => {
    console.log("issueId", issueId);
    dispatch(
      updateIssueData({
        auditId: params?.auditId,
        changes: { status: 9 },
        issueId,
      })
    );
  };
  //   const uploadedProofWithUrl = useSelector(selectAuditCAPUploadedProofWithUrl);

  return (
    data.status !== "SITE_ADDING_PROOF" &&
    data.status !== "BRAND_APPROVED_PLAN" && (
      <Grid>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              color: "#475467",
              fontSize: "30px",
              fontWeight: 500,
              textDecorationLine: "underline",
              my: 4,
              overflowWrap: "break-word",
              wordWrap: "break-word",
              hyphens: "auto",
            }}
          >
            Uploaded Proof
          </Typography>
          {data?.status === "SITE_ADDED_PROOF" ? (
            <Button
              variant="contained"
              sx={{
                ml: 2,
                textTransform: "none",
                backgroundColor: "#2D3282",
                borderRadius: "8px",
                border: "1px solid #2D3282",
                padding: "4px 16px",
                fontSize: "16px",
                fontWeight: "600",
              }}
              onClick={() => {
                handleApprove(selectedIssueId);
              }}
            >
              Approve
            </Button>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#4CAF50",
                ml: 2,
              }}
            >
              <CheckCircleIcon sx={{ mr: 1 }} />
              <Typography sx={{ fontWeight: 600 }}>Approved</Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            padding: "12px",
            borderRadius: "12px",
            background: "#b6b6b614",
          }}
        >
          <List sx={{ p: 0 }}>
            {uploadedProof?.map((proof, index) => (
              <ListItem key={index} sx={{ p: 1 }}>
                <Link
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#45464E",
                    textDecoration: "underline",
                    textDecorationColor: "#45464E",
                    "&:hover": {
                      textDecorationColor: "rgba(69, 70, 78, 0.8)",
                    },
                  }}
                  href={proof.url}
                  target="_blank"
                >
                  {proof.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    )
  );
};

export default AuditCapDetailsStatusUploadedProof;
