import React from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditIssueCapDataStatus,
  selectCurrentStepInCapManagement,
  selectFirstAuditIssueId,
} from "../../selectors/audit.selector";
import { setSelectedAuditIssueId } from "../../reducers/audit.reducer";

const AuditCapSubProgressStepper = ({ steps, currentStep }) => {
  console.log("steps", steps);
  console.log("currentStep", currentStep);
  const auditIssueCapDataStatus = useSelector(selectAuditIssueCapDataStatus);

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={4}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "600",
          }}
        >
          Current Step: {steps[currentStep]?.label}
        </Typography>

        <Box display="flex" alignItems="center" mt={1}>
          <LinearProgress
            variant="determinate"
            value={
              ((auditIssueCapDataStatus?.completed || 0) /
                auditIssueCapDataStatus?.total) *
              100
            }
            sx={{
              width: "100%",
              height: 10,
              mr: 2,
              color: "#303F9F",
            }}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {auditIssueCapDataStatus?.completed || 0}/
            {auditIssueCapDataStatus?.total}
          </Typography>
        </Box>
      </Grid>

      {/* Last updated information */}
    </Grid>
  );
};

export default AuditCapSubProgressStepper;
