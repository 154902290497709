import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Switch,
  Grid,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import EditToggleSwitch from "../editToggleSwitch.component";
import EditAuditReportToggleSwitch from "./editAuditReportToggleSwitch.component";
import AuditCapProgressStepper from "./auditCapProgressStepper.component";
import { selectAuditData } from "../../selectors/audit.selector";
import { useSelector } from "react-redux";

const AuditReportDetailsAppbar = ({ handleDrawerClose }) => {
  const [type, setType] = useState("");
  const [findingCategory, setFindingCategory] = useState("");
  const [capStatus, setCapStatus] = useState("");
  const [editMode, setEditMode] = useState(false);

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const selectedAuditData = useSelector(selectAuditData);

  return (
    <AppBar
      color="inherit"
      position="static"
      sx={{
        textAlign: "center",
        fontSize: "16px",
        fontWeight: 500,
        borderBottom: "1px solid #E0E0E0",
      }}
      elevation={0}
    >
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs={4}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              sx={{ alignItems: "flex-start", display: "flex" }}
              onClick={handleDrawerClose}
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={4}>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: "center",
                paddingTop: "15px",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Audit Review and CAP management
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: "15px", paddingRight: "-100px" }}>
            <EditAuditReportToggleSwitch />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: "15px", paddingBottom: "15px" }}>
            <AuditCapProgressStepper
              capDetails={true}
              auditData={selectedAuditData}
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AuditReportDetailsAppbar;
